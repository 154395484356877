import React,{Component} from 'react';
import {NavLink, Link} from 'react-router-dom';
class Header extends Component {


	render(){
		return (

<header id='header' className='header'>
	<nav className='navigation'>
		<div className='navigation__item'>
			<Link to='/' className='navigation__link navigation__link-main'>
				Toshikazu, H
			</Link>
		</div>
		{/* <div className='navigation__item'>
			<NavLink to='/portfolio' className='navigation__link'>Projets</NavLink>
		</div> */}
		<div className='navigation__item'>
			{/* <NavLink to='/me' className='navigation__link'>About</NavLink> */}
			<a
              href='https://toshikazu-horioka.notion.site/WHO-I-AM-EN-b9ef8251f43a4c56a2423cb97ee9de66?pvs=4'
              className='navigation__link'
              target='_blank'
              rel='noopener noreferrer'
            >About</a>
		</div>
	</nav>
</header>

		);
	}


}

export default Header;
